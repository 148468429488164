import React from "react"
import Button from "./Button"
import { PrismicRichText } from "@prismicio/react"

const SplitSection = ({
  id,
  title,
  description,
  imageUrl,
  reverseOrder,
  buttonTitle = "Jetzt testen",
  buttonLink = "http://contact.chatchamp.com/testzugang",
}) => (
  <section id={id} className="py-8 md:py-16 lg:py-20 xl:py-20">
    <div className="container mx-auto px-8 items-center flex flex-col lg:flex-row">
      <div className="lg:w-2/5">
        <div className={`${reverseOrder ? `xl:pl-16` : `lg:pr-8 xl:pr-16`}`}>
          <h3 className="text-3xl font-semibold leading-tight">{title}</h3>
          <p className="mt-8 text-m font-light leading-relaxed">
            <PrismicRichText field={description} />
          </p>
          <div className="mt-8 md:mt-12">
            <Button size="xl" link={buttonLink}>
              {buttonTitle}
            </Button>
          </div>
        </div>
      </div>
      <div
        className={`mt-10 lg:mt-0 w-full lg:w-3/5 ${reverseOrder &&
          `order-last lg:order-first`}`}
      >
        <img src={imageUrl} />
      </div>
    </div>
  </section>
)

export default SplitSection
