import React from "react"

const CustomerCard = ({ customer }) => (
  <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
    <div className="shrink-0">
      {/* <a href={customer.customer_link.url} target="_blank" rel="noopener noreferrer"> */}
      <img
        className="h-48 w-full object-cover"
        src={customer.card_image.url}
        alt=""
      />
      {/* </a> */}
    </div>
    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
      <div className="flex-1">
        <p className="text-sm leading-5 font-medium text-indigo-600">
          {/*<a href={customer.customer_link.url} className="hover:underline" target="_blank" rel="noopener noreferrer">*/}
          {customer.content_type.text}
          {/*</a>*/}
        </p>
        {/*<a href={customer.customer_link.url} className="block" target="_blank" rel="noopener noreferrer">*/}
        <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
          {customer.title.text}
        </h3>
        <p className="mt-3 text-base leading-6 text-gray-600">
          {customer.quote.text}
        </p>
        {/*</a>*/}
      </div>
      <div className="mt-6 flex items-center">
        <div className="shrink-0">
          {/*<a href={customer.customer_link.url} target="_blank" rel="noopener noreferrer">*/}
          <img
            className="h-10 w-10 rounded-full"
            src={customer.avatar.url}
            alt={customer.customer_name.text}
          />
          {/*</a>*/}
        </div>
        <div className="ml-3">
          <p className="text-sm leading-5 font-medium text-gray-900">
            {/*<a href={customer.customer_link.url} className="hover:underline" target="_blank" rel="noopener noreferrer">*/}
            {customer.customer_name.text}
            {/*</a>*/}
          </p>
          <div className="flex text-sm leading-5 text-gray-600">
            <span>{customer.customer_position.text}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default CustomerCard
